import { numberWithSpaces } from '@core/utils'
import { Box, Button, Typography } from '@mui/material'
import { theme } from '@remoteam-front/ui'
import { useBoolean } from 'usehooks-ts'

import { CompensationApproveConfirm } from './compensation-approve-confirm.component'
import { OnboardingTrackerCompensationTable } from './onboarding-tracker-compensation-table.component'
import { Styled } from './onboarding-tracker-required-compensation.styles'

export const OnboardingTrackerRequiredCompensation = ({
  employee,
  isApproved,
}) => {
  const approveOpen = useBoolean()

  const compensations = [
    ...(employee.compensation?.additional_compensations || []),
    ...(employee.compensation?.allowances || []),
    ...(employee.compensation?.signing_bonus
      ? [employee.compensation.signing_bonus]
      : []),
  ]

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="textLarge2">Compensation</Typography>
        {!isApproved && !!compensations?.length && (
          <Button variant="contained" onClick={approveOpen.setTrue}>
            Approve
          </Button>
        )}
      </Box>
      <Styled.Card>
        <Typography variant="subtitle2">Salary</Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Styled.Doc>
            <Typography variant="body1" color={theme.palette.secondary[300]}>
              Annual gross salary
            </Typography>
            <Typography
              variant="subtitle2"
              color={theme.palette.primary[300]}
            >{`${
              employee.compensation?.currency?.sign ||
              employee.compensation?.currency?.short_code
            } ${numberWithSpaces(
              employee.compensation.yearly_gross_salary
            )}`}</Typography>
          </Styled.Doc>
          <Styled.Doc>
            <Typography variant="body1" color={theme.palette.secondary[300]}>
              No. of payments per year
            </Typography>
            <Typography variant="subtitle2" color={theme.palette.primary[300]}>
              {employee.working_country.yearly_salary_divider}
            </Typography>
          </Styled.Doc>
          <Styled.Doc>
            <Typography variant="body1" color={theme.palette.secondary[300]}>
              Amount per payment
            </Typography>
            <Typography
              variant="subtitle2"
              color={theme.palette.primary[300]}
            >{`${
              employee.compensation?.currency?.sign ||
              employee.compensation?.currency?.short_code
            } ${numberWithSpaces(
              employee.compensation.monthly_salary
            )}`}</Typography>
          </Styled.Doc>
        </Box>
      </Styled.Card>

      <OnboardingTrackerCompensationTable
        compensationData={compensations}
        isApproved={isApproved}
      />
      {approveOpen.value && (
        <CompensationApproveConfirm
          onClose={approveOpen.setFalse}
          compensationId={employee.compensation?.id}
        />
      )}
    </Box>
  )
}
