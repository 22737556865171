import { DeleteOutlined, EditOutlined } from '@mui/icons-material'
import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { InfoRegularIcon } from '@remoteam-front/ui/icons'
import { capitalizeFirstLetter } from '@remoteam-front/utils'
import { useState } from 'react'

import { CompensationDeleteDialog } from './compensation-delete-dialog.component'
import { CompensationUpdateDrawer } from './compensation-update-drawer.component'
import { recurrenceFrequency } from './consts'
import { EmptyState } from './empty-state'
import { Styled } from './onboarding-tracker-required-compensation.styles'

export const OnboardingTrackerCompensationTable = ({
  compensationData,
  isApproved,
}) => {
  const [apModalOpen, setApModalOpen] = useState()
  const [deleteOpen, setDeleteOpen] = useState()

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="textLarge2" className="mt-3">
        Additional compensation
      </Typography>
      {!isApproved && (
        <Box>
          <Chip
            label="Please complete tax info and verify contractual info before approving."
            size="small"
            color="warning"
            className="px-1"
            icon={<InfoRegularIcon />}
          />
        </Box>
      )}

      {!compensationData.length ? (
        <EmptyState />
      ) : (
        <Table data-testid="onboarding-tracker-required-compensation.component-E157EE">
          <TableHead>
            <TableRow>
              <Styled.TableHeadCell>Title</Styled.TableHeadCell>
              <Styled.TableHeadCell>Type</Styled.TableHeadCell>
              <Styled.TableHeadCell>Amount</Styled.TableHeadCell>
              <Styled.TableHeadCell>Frequency</Styled.TableHeadCell>
              <Styled.TableHeadCell>Tax</Styled.TableHeadCell>
              <Styled.TableHeadCell>Contractual</Styled.TableHeadCell>
              {!isApproved && (
                <Styled.TableHeadCell align="left" width={80}>
                  Action
                </Styled.TableHeadCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {compensationData.map((ap) => (
              <TableRow key={ap.id}>
                <TableCell>{ap.name}</TableCell>
                <TableCell>{capitalizeFirstLetter(ap.ap_type)}</TableCell>
                <TableCell>
                  {`${ap.amount} ${ap.currency.sign || ap.currency.short_code}`}
                </TableCell>
                <TableCell>
                  {ap.is_recurrent
                    ? `${
                        recurrenceFrequency.find(
                          (rf) => rf.value === ap.recurrence_frequency
                        )?.label || 'Other'
                      }`
                    : 'One-time'}
                </TableCell>
                <TableCell>
                  {ap.tax
                    ? `${ap.tax} ${ap.currency.sign || ap.currency.short_code}`
                    : 'Non-taxable'}
                </TableCell>
                <TableCell>{ap.is_contractual ? 'Yes' : 'No'}</TableCell>
                {!isApproved && (
                  <TableCell align="right">
                    <Box display="flex" gap={2} justifyContent="end">
                      <Styled.ActionButton
                        variant="outlined"
                        color="secondary"
                        startIcon={<EditOutlined className="mr-0" />}
                        size="small"
                        onClick={() => setApModalOpen(ap)}
                      />
                      <Styled.ActionButton
                        variant="outlined"
                        color="secondary"
                        size="small"
                        startIcon={<DeleteOutlined color="error" />}
                        onClick={() => setDeleteOpen(ap)}
                      />
                    </Box>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {!!apModalOpen && (
        <CompensationUpdateDrawer
          compensation={apModalOpen}
          onClose={() => setApModalOpen(undefined)}
        />
      )}
      {!!deleteOpen && (
        <CompensationDeleteDialog
          compensation={deleteOpen}
          onClose={() => setDeleteOpen(undefined)}
        />
      )}
    </Box>
  )
}
