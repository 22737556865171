import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { ProbationResponse } from '@remoteam-front/types/legacy'
import { v4 } from 'uuid'

type Props = {
  data: ProbationResponse
}

export const ProbationPeriodTable = ({ data }: Props) => {
  const mappedData = [
    {
      type: 'Fixed',
      isApplicable: data.fixed_contract_is_applicable,
      min: data.fixed_contract_minimum_day,
      max: data.fixed_contract_maximum_day,
      suggested: data.fixed_contract_suggested_day,
      note: data.fixed_contract_note,
    },
    {
      type: 'Permanent',
      isApplicable: data.permanent_contract_is_applicable,
      min: data.permanent_contract_minimum_day,
      max: data.permanent_contract_maximum_day,
      suggested: data.permanent_contract_suggested_day,
      note: data.permanent_contract_note,
    },
  ]

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Contract type</TableCell>
          <TableCell align="left">Applicable</TableCell>
          <TableCell align="left">Minimum</TableCell>
          <TableCell align="left">Maximum</TableCell>
          <TableCell align="left">Suggested</TableCell>
          <TableCell align="center">Note</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {mappedData.map(({ isApplicable, max, min, note, suggested, type }) => (
          <TableRow key={v4()}>
            <TableCell
              sx={{ minWidth: 150 }}
              align="left"
              component="th"
              scope="row"
            >
              {type}
            </TableCell>
            <TableCell align="left">
              {isApplicable ? (
                <Chip label="Yes" color="success" />
              ) : (
                <Chip label="No" />
              )}
            </TableCell>
            <TableCell align="left">
              {min !== null ? `${min} days` : '-'}
            </TableCell>

            <TableCell align="left">
              {max !== null ? `${max} days` : '-'}
            </TableCell>

            <TableCell align="left">
              {suggested !== null ? `${suggested} days` : '-'}
            </TableCell>

            <TableCell
              align="left"
              sx={{ minWidth: '200px', wordBreak: 'break-all' }}
            >
              {note || '-'}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
