import { ApiNoResInterceptor } from '@api/Api'
import { ProbationResponse } from '@remoteam-front/types/legacy'
import { generateService } from '@remoteam-front/utils'

export const getProbationPeriod = generateService(async (id: number) => {
  const { data } = await ApiNoResInterceptor.get<ProbationResponse>(
    `/countries/${id}/probation-period-detail/`
  )

  return data
})
