import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import Icon from '@atoms/Icon/Icon'
import NotFound from '@atoms/NotFound/NotFound'
import Status from '@atoms/Status/Status'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { deleteIcon, edit, keyboardArrowLeft } from '@core/icons/icons'
import { numberWithSpaces } from '@core/utils'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import PayrollDetails from '@pages/payrollsDetailPage/PayrollDetails'
import {
  fetchDeletePayroll,
  getPayrollByCompanyId,
  getPayrollInvoiceTotal,
  partialApprovePayroll,
  postCalculateLastDay,
} from '@services/payroll.service'
import { DotWave } from '@uiball/loaders'
import classNames from 'classnames'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'
import { v4 } from 'uuid'

const SelectTable = ({ isClickedBack }) => {
  const { profile } = useApp()
  const router = useRouteMatch()

  const currentDate = new Date(moment(new Date()).format('YYYY-MM-DD'))
  const [invoiceId, setInvoiceId] = useState()
  const [payrollsData, setPayrollsData] = useState([])
  const [invoiceTotal, setInvoiceTotal] = useState()
  const history = useHistory()
  const [payrollCurrent, setPayrollCurrent] = useState()
  const [currentPage, setCurrentPage] = useState(0)
  const [activeId, setActiveId] = useState()
  const [selectedRowIds, setSelectedRowIds] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [selectedPayrollDelete, setSelectedPayrollDelete] = useState(null)

  const { value: visibleDelete, toggle: toggleDelete } = useBoolean(false)
  const { value: visiblePartialApprove, toggle: togglePartialApprove } =
    useBoolean(false)
  const { value: visibleApprove, toggle: toggleApprove } = useBoolean(false)

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['getPayrollByCompanyId', currentPage, router.params.id],
    {
      queryFn: () =>
        getPayrollByCompanyId(router.params.id, {
          state: 'pending',
          limit: 10000,
        }),
    }
  )

  const approvePayrollRequest = useMutation('payrollsChangeStatus', {
    mutationFn: (payload) => partialApprovePayroll(payload),
    onSuccess: () => {
      if (selectedRowIds.length === payrollCurrent.count) {
        toggleApprove()
      } else {
        toast.success('Successfully updated')
      }
      setSelectedRowIds([])
      refetch()
    },
  })

  const { isLoading: invoiceTotalLoading } = useQuery(
    ['invoiceTotal', invoiceId],
    {
      queryFn: () => getPayrollInvoiceTotal(invoiceId),
      onSuccess: (res) => {
        setInvoiceTotal(res)
      },
      enabled: !!invoiceId,
    }
  )

  const deleteMutate = useMutation({
    mutationFn: (payrollId) => fetchDeletePayroll(payrollId),
    onSuccess: () => {
      refetch()
      toggleDelete()
      toast.success('Payroll successfully deleted')
    },
  })

  const postCalculateLastDayMutate = useMutation('postCalculateLastDay', {
    mutationFn: (payload) => postCalculateLastDay(payload),
  })

  const handleDelete = (row) => {
    toggleDelete()
    setSelectedPayrollDelete(row.id)
  }

  const loadMoreData = () => {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  const toggleCollapse = (id) => {
    setActiveId((prevState) => (prevState === id ? undefined : id))
    setInvoiceId(id)
  }

  const handleRowSelect = (payroll) => {
    setSelectedRowIds((prevSelectedRowIds) =>
      prevSelectedRowIds.some((p) => p.id === payroll.id)
        ? prevSelectedRowIds.filter((rowId) => rowId.id !== payroll.id)
        : [...prevSelectedRowIds, payroll]
    )
  }

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRowIds([])
    } else {
      setSelectedRowIds(data.payrolls)
    }
    setSelectAll(!selectAll)
  }

  const onCreateInvoice = useCallback(() => {
    approvePayrollRequest.mutate({
      payrolls: selectedRowIds.map((sr) => sr.id),
    })
  }, [selectedRowIds])

  const onClickBack = () => {
    history.goBack()
  }

  const onClickEdit = useCallback((rowData) => {
    history.push(`/pages/payrolls/edit/${rowData.id}`)
  }, [])

  const checkDisable = () => {
    if (postCalculateLastDayMutate?.data?.last_day)
      return (
        currentDate <=
        new Date(
          moment(new Date(postCalculateLastDayMutate.data.last_day)).format(
            'YYYY-MM-DD'
          )
        )
      )
    return false
  }

  useEffect(() => {
    loadMoreData()
  }, [])

  useEffect(() => {
    if (profile) {
      postCalculateLastDayMutate.mutate({
        country: profile?.allowed_countries[0]?.id,
        days: 3,
      })
    }
  }, [profile])

  useEffect(() => {
    if (data) {
      setPayrollCurrent({
        currency: data?.currency,
        count: data?.count,
      })
      setPayrollsData(data)
      setInvoiceId(data[0]?.current?.payrolls[0]?.id)
    }
  }, [data])

  useEffect(() => {
    if (data?.count === 0) {
      history.goBack()
    } else if (isClickedBack) {
      togglePartialApprove()
    }
  }, [isClickedBack])

  if (isLoading || isFetching)
    return (
      <div className="d-flex w-100 vh-100 align-items-center justify-content-center">
        <DotWave size={48} speed={1} color="black" />
      </div>
    )

  return (
    <>
      {payrollsData?.payrolls?.length > 0 ? (
        <InfiniteScroll
          dataLength={payrollsData.total_count}
          hasMore={false}
          loader={<h4>Loading...</h4>}
        >
          <table>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
                <th>Name</th>
                <th>Country</th>
                <th>Gross salary</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {payrollsData.payrolls.map((payroll) => (
                <React.Fragment key={payroll.id}>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedRowIds.some(
                          (pr) => pr.id === payroll.id
                        )}
                        onChange={() => handleRowSelect(payroll)}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <Button
                        priority="outlined"
                        style={{ height: '32px' }}
                        size="small"
                        className="px-2 pl-0"
                        onClick={() => toggleCollapse(payroll.id)}
                      >
                        <Icon
                          icon={keyboardArrowLeft}
                          fill="black"
                          style={{
                            cursor: 'pointer',
                            transform: 'rotate(0)',
                          }}
                        />
                      </Button>
                    </td>
                    <td>
                      {payroll.user.full_name}
                      {payroll.indication !== 'regular' && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip>
                              {payroll.indication === 'first'
                                ? `First payroll of the employee starting on ${
                                    payroll?.job?.starts_at
                                      ? moment(payroll?.job?.starts_at).format(
                                          'DD MMM YYYY'
                                        )
                                      : ''
                                  }`
                                : `Last payroll of the employee terminating on ${
                                    payroll?.job?.ends_at
                                      ? moment(payroll?.job?.ends_at).format(
                                          'DD MMM YYYY'
                                        )
                                      : ''
                                  }`}
                            </Tooltip>
                          }
                        >
                          <span
                            className={`text_regular__14 ${
                              payroll.indication === 'first'
                                ? 'color_blue'
                                : 'color_red'
                            }`}
                            style={{
                              marginLeft: 4,
                              verticalAlign: 'top',
                              padding: 4,
                              border: `1px solid ${
                                payroll.indication === 'first'
                                  ? '#2967bc'
                                  : '#ef4343'
                              }`,
                            }}
                          >
                            {payroll.indication === 'first'
                              ? 'First payroll'
                              : 'Terminating'}
                          </span>
                        </OverlayTrigger>
                      )}
                    </td>
                    <td>{payroll?.country?.name}</td>
                    <td>
                      {payroll?.partner_currency?.sign ||
                        payroll?.partner_currency?.short_code}{' '}
                      {numberWithSpaces(payroll?.gross_pay_in_partner_currency)}
                    </td>
                    <td>
                      <Status status={`flag_payroll_${payroll.state}`} />
                    </td>
                    {payroll.state === 'created' && (
                      <td>
                        <div className="d-flex">
                          <Button
                            priority="secondary"
                            style={{ height: '32px' }}
                            size="small"
                            className="px-2 mr-2 pl-0"
                            onClick={() => onClickEdit(payroll)}
                          >
                            <Icon fill="none" icon={edit} />
                            <Typography
                              className={classNames('text_light__12', 'ml-2')}
                            >
                              Edit
                            </Typography>
                          </Button>
                          <Button
                            priority="outlined"
                            style={{ height: '32px' }}
                            size="small"
                            className="px-2 pl-0"
                            onClick={() => handleDelete(payroll)}
                          >
                            <Icon fill="none" icon={deleteIcon} />
                          </Button>
                        </div>
                      </td>
                    )}
                  </tr>
                  {activeId === payroll.id && (
                    <tr>
                      <td colSpan="6">
                        {invoiceTotalLoading ? (
                          <div
                            className="d-flex w-100 align-items-center justify-content-center"
                            style={{ height: 200 }}
                          >
                            <DotWave size={32} speed={1} color="black" />
                          </div>
                        ) : (
                          <PayrollDetails
                            payroll={payroll}
                            invoiceTotal={invoiceTotal}
                          />
                        )}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      ) : (
        <NotFound
          illustration="/assets/img/ec-empty-shopping-cart.png"
          title="No pending payroll"
          description="Payroll will be generated on the 1st day of every month and appear here "
        />
      )}
      <div className="payroll-bottom-wrapper">
        <div className="payroll-bottom d-flex justify-content-center">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div>
              <Typography className="heading_semibold__20">
                Total:{' '}
                {numberWithSpaces(
                  selectedRowIds
                    .map((sr) => sr.total_in_partner_currency)
                    .reduce((acc, item) => acc + item, 0)
                )}{' '}
                {payrollCurrent?.currency?.sign}
              </Typography>
            </div>
            <div className="d-flex align-items-center">
              <Button
                className="px-5"
                priority="primary"
                size="medium"
                disabled={checkDisable()}
                loading={approvePayrollRequest.isLoading}
                onClick={onCreateInvoice}
              >
                Approve
              </Button>
              {checkDisable() && (
                <Tooltip
                  id={v4()}
                  content="The approval button is active
              from the 4th working day of the month. This provides
              clients the opportunity to add expenses & additional payments for the month."
                  style={{ width: 400 }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <EditModal
        visible={visibleDelete}
        title="Delete payroll"
        onSave={() => {}}
        footer={false}
        closeModal={toggleDelete}
      >
        <Typography className="heading_semi__16">
          Are you sure you want to delete this payroll?
        </Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={toggleDelete}
          >
            Cancel
          </Button>
          <Button
            priority="primary_dangerous"
            size="small"
            loading={deleteMutate.isLoading}
            onClick={() => deleteMutate.mutate(selectedPayrollDelete || '')}
          >
            Delete
          </Button>
        </div>
      </EditModal>
      <Dialog open={visiblePartialApprove} onClose={togglePartialApprove}>
        <div className="d-flex justify-content-center w-100 my-4">
          <img
            style={{ width: 'fit-content' }}
            src="/assets/icons/oc-pie-chart.svg"
            alt="oc-pie-chart"
          />
        </div>
        <DialogTitle>
          You have only partially approved company payroll
        </DialogTitle>
        <DialogContent>
          Please complete payroll approval as soon as possible to avoid any
          delay in payment.
        </DialogContent>
        <DialogActions>
          <div className="d-flex justify-content-center w-100">
            <Button
              size="small"
              variant="outlined"
              color="success"
              onClick={onClickBack}
            >
              Continue
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog open={visibleApprove} onClose={toggleApprove}>
        <div className="d-flex justify-content-center w-100 my-4">
          <img
            style={{ width: 'fit-content' }}
            src="/assets/icons/oc-hand-holding-papers.svg"
            alt="oc-pie-chart"
          />
        </div>
        <DialogTitle>You have approved company payroll</DialogTitle>
        <DialogContent>
          These entries will now be located under Approved payroll. You will now
          be taken back to Pending payroll.
        </DialogContent>
        <DialogActions>
          <div className="d-flex justify-content-center w-100">
            <Button
              size="small"
              variant="outlined"
              color="success"
              onClick={onClickBack}
            >
              Continue
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SelectTable
