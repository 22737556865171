import './style.scss'

import classNames from 'classnames'
import React from 'react'

import DocumentsState from './DocumentsState'

export default function Status({ status, documents_state, isOnboardingTab }) {
  if (!status) return <div className="status status_default">No Status</div>
  switch (status.toLowerCase()) {
    case 'in_progress':
      return <div className="status status_default">In Progress</div>
    case 'awaiting_approval':
      return (
        <div className="status status_warning">Awating client approval</div>
      )
    case 'timeoff_pending':
      return <div className="status status_default">Pending</div>
    case 'complete':
      return <div className="status status_success">Complete</div>
    case 'completed':
      return <div className="status status_success">Complete</div>
    case 'active':
      return <div className="status status_success">Active</div>
    case 'app_accepted':
      return <div className="status status_success">Active</div>
    case 'onboarding':
      if (!isOnboardingTab) {
        return <div className="status status_success">Onboarding</div>
      }
      return (
        <div
          className={classNames('status', {
            status_success: documents_state === 'waiting_document_approval',
          })}
        >
          <DocumentsState documents_state={documents_state} />
        </div>
      )
    case 'created':
      return <div className="status status_success">Created</div>
    case 'app_submitted':
      return <div className="status status_success">Application submitted</div>
    case 'generating':
      return <div className="status status_disabled">Generating</div>
    case 'confirming':
      return <div className="status status_default">Confirming</div>
    case 'late':
      return <div className="status status_error">Overdue</div>
    case 'paid':
      return <div className="status status_success">Paid</div>
    case 'partner_invoice_created':
      return (
        <div className="status status_success">Partner invoice created</div>
      )
    case 'failed':
      return <div className="status status_error">Failed</div>
    case 'pending':
      return <div className="status status_default">Pending</div>
    case 'awaiting_payment':
      return <div className="status status_warning">Awaiting payment</div>
    case 'archived':
      return <div className="status status_warning">Archived</div>
    case 'information_requested':
      return <div className="status status_active">Queried</div>
    case 'cancelled':
      return <div className="status status_warning">Cancelled</div>
    case 'canceled':
      return <div className="status status_warning">Cancelled</div>
    case 'refunded':
      return <div className="status status_default">Refunded</div>
    case 'in_active':
      return <div className="status status_default">Inactive</div>
    case 'inactive':
      return <div className="status status_default">Inactive</div>
    case 'need_changes_from_partner':
      return <div className="status status_error">Need change from partner</div>
    case 'approved_by_partner':
      return <div className="status status_success">Approved by partner</div>
    case 'approved_by_admin':
      return <div className="status status_success">Approved by admin</div>
    case 'generating_invoice':
      return <div className="status status_default">Generating Invoice</div>
    case 'approved_by_client':
      return <div className="status status_success">Approved by client</div>
    case 'invoice_created':
      return <div className="status status_default">Invoice created</div>
    case 'signed':
      return <div className="status status_success">Signed</div>
    case 'rejected':
      return <div className="status status_error">Rejected</div>
    case 'approved':
      return <div className="status status_success">Approved</div>
    case 'taken':
      return <div className="status status_active">Taken</div>
    case 'not_signed':
      return <div className="status status_default">Pending</div>
    case 'returned_after_cancellation':
      return (
        <div className="status status_default">Returned after cancellation</div>
      )
    case 'uploaded':
      return <div className="status status_success">Uploaded</div>
    case 'draft':
      return <div className="status status_default">Draft</div>
    case 'need_changes_from_admin':
      return <div className="status status_error">Need change from admin</div>
    case 'monthly':
      return <div className="status status_success">Monthly</div>
    case 'every_second_month':
      return <div className="status status_success">Every second month</div>
    case 'quarterly':
      return <div className="status status_success">Quarterly</div>
    case 'to_approve':
      return <div className="status status_warning">To Approve</div>
    case 'partially_approved':
      return <div className="status status_warning">Partially Approved</div>
    case 'flag_payroll_created':
      return <div className="status status_active">To Approve</div>
    case 'flag_payroll_approved_by_partner':
      return <div className="status status_success">Approved</div>
    case 'flag_payroll_approved_by_admin':
      return <div className="status status_success">Approved</div>
    case 'flag_payroll_approved_by_client':
      return <div className="status status_success">Approved</div>
    case 'flag_payroll_returned_after_cancellation':
      return <div className="status status_success">Approved</div>
    case 'flag_payroll_returned_invoice_created':
      return <div className="status status_default">Approved</div>
    default:
      return <div>{status}</div>
  }
}
